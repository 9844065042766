import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }
  
  body {
    font-family: ${(props) => props.theme.utils.global.fontFamily};
    font-size: ${(props) => `${props.theme.utils.global.fontSize}rem`};
    line-height: ${(props) => props.theme.utils.global.lineHeight};

    color: ${(props) => props.theme.palette.typography};
  }
`;

export const Global = () => {
  return (
    <React.Fragment>
      <Normalize />
      <GlobalStyle />
    </React.Fragment>
  )
};
