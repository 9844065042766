import React, { useCallback, useMemo } from 'react'
// Heads-Up: The Widgets-Package cannot be imported here. It causes weird build-time failures.
// Therefore, Gatsby's Link implementation is used directly.
import { Link } from 'gatsby-plugin-react-i18next';

import { MenuSubEntry, SubMenu } from './Menu'
import { Item, ItemLink, SubItems } from './SubMenu.styles'
import { useMenuContext } from './MenuContext'

const RenderLink: React.FC<{
  to?: string
  onClick?: () => void
  $open?: boolean
  $hasChildren?: boolean
  $palette: SubMenu['palette']
}> = ({ to, onClick, $open, $hasChildren, $palette, children }) => {
  const { onNavigation } = useMenuContext()

  if (to) {
    return <ItemLink as={Link} onClick={onNavigation} to={to} $open={$open} $hasChildren={$hasChildren} $palette={$palette}>{children}</ItemLink>
  }

  return <ItemLink onClick={onClick} $open={$open} $hasChildren={$hasChildren} $palette={$palette}>{children}</ItemLink>
}

type SubMenuItemProps = {
  parent_id: string[]
  palette: SubMenu['palette']
	item: MenuSubEntry
}
const SubMenuItem: React.FC<SubMenuItemProps> = ({ parent_id, item, palette }) => {
  const { isSubMenuOpen, setSubMenuOpen } = useMenuContext()
  const parentsWithSelf = useMemo(() => {
    return [...parent_id, item.id]
  }, [parent_id, item])

  const open = useMemo(() => {
    return isSubMenuOpen(parentsWithSelf)
  }, [isSubMenuOpen, parentsWithSelf])

  const toggle = useCallback(() => {
    setSubMenuOpen(parentsWithSelf)
  }, [setSubMenuOpen, parentsWithSelf])

	return <Item>
    <RenderLink onClick={toggle} to={item.href} $open={open} $hasChildren={item.items && item.items.length > 0} $palette={palette}>
      {item.title}
    </RenderLink>

    {item.items && item.items.length > 0 && <SubItems $open={open}>
      {item.items.map((subitem) => <SubMenuItem
        item={subitem}
        palette={palette}
        parent_id={parentsWithSelf}
        key={`item-${item.id}-subitem-${subitem.id}`}
      />)}
    </SubItems>}
	</Item>
}

export default SubMenuItem
