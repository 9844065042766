import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

const GDPRCookies = [
  {
    label: 'Google Analytics',
    key: 'googleAnalytics',
    trackingValue: 'trackingId',
  }, {
    label: 'Google Tag Manager',
    key: 'googleTagManager',
    trackingValue: 'trackingId',
  },
  {
    label: 'Facebook',
    key: 'facebookPixel',
    trackingValue: 'pixelId',
  }
];

export const useCookiesMap = () => {
  if (typeof window === 'undefined') return [];

  const opts = _get(window, 'gatsbyPluginGDPRCookiesOptions');

  if (!opts) return [];

  return _reduce(GDPRCookies, (acc, cookie) => {
    const value = _get(opts, `${cookie.key}.${cookie.trackingValue}`);

    if (value) {
      acc.push({
        cookieName: _get(opts, `${cookie.key}.cookieName`),
        ...cookie,
      });
    }

    return acc;
  }, []);
};

export const useCookiesTrack = () => {
  const location = useLocation();
  return () => initializeAndTrack(location);
}

export const GDPRConsentCookie = 'gdpr-consent';
