import styled from 'styled-components';

import Button from '../Button';

export const Container = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: absolute;
  width: 800px;
  max-width: 80%;
  height: 400px;
  max-height: 90%;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: 100;
`;

export const Backdrop = styled.div`
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`;

export const IconClose = styled(Button)`
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Content = styled.div`
 padding: 3rem;
 height: 100%;
 overflow: auto;
`;
