import { light } from './palette';
import utils from './utils';

const themes = {
  light: {
    palette: light,
    utils,
  },
}

export default themes
