import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type { MenuEntryType } from './Menu'
import { Entry, EntryItemWrap, SubMenus, SubMenusDropdown, EntryLink } from "./Menu.styles"
import SubMenu from './SubMenu'
import { useMenuContext } from './MenuContext'
import { Link } from 'gatsby-plugin-react-i18next'

type SubMenuWrapProps = {
  id: MenuEntryType['id']
  submenus: NonNullable<MenuEntryType['submenus']>
}
const RenderSubMenu: React.FC<SubMenuWrapProps> = ({ id, submenus }) => {
  const { isSubMenuOpen } = useMenuContext()

  const open = useMemo(() => {
    return isSubMenuOpen([id])
  }, [isSubMenuOpen, id])

  const ref = useRef<HTMLDivElement|null>(null)
  const [height, setHeight] = useState<number|undefined>()
  const updateHeight = useCallback((instance: typeof ref['current']) => {
    ref.current = instance

    if (!instance) {
      return
    }

    setHeight(instance.offsetHeight)
  }, [])

  useEffect(() => {
    const cb = () => {
      updateHeight(ref.current)
    }

    window.addEventListener("resize", cb)

    return () => {
      window.removeEventListener("resize", cb)
    }
  }, [updateHeight])

  return <SubMenusDropdown ref={updateHeight} $open={open} $height={height}>
    <SubMenus>
      {submenus.map(submenu => <SubMenu parent_id={id} submenu={submenu} key={`${id}-${submenu.id}`} />)}
    </SubMenus>
  </SubMenusDropdown>
}

type MenuEntryProps = {
	entry: MenuEntryType
}
const MenuEntry: React.FC<MenuEntryProps> = ({ entry }) => {
  const { id, title } = entry

  const { setSubMenuOpen, onNavigation, setMenuTitle } = useMenuContext()
  const entryRef = useRef<HTMLSpanElement>(null)

  const toggle = useCallback<MouseEventHandler>((event) => {
    if (event.target instanceof Element && event.target.tagName === "A") {
      // reset menu state
      onNavigation()
      return
    }

    setSubMenuOpen([id])
    setMenuTitle(title)
  }, [id, title, onNavigation, setSubMenuOpen, setMenuTitle])

  return <Entry>
    <EntryItemWrap
      ref={entryRef}
      $hasChildren={entry.submenus && entry.submenus.length > 0}
      $topLevel
      onClick={toggle}
    >
      {entry.item && <>{entry.item}</>}
      {!entry.item && <>
        {entry.href && <EntryLink as={Link} to={entry.href}>{entry.title}</EntryLink>}
        {!entry.href && <EntryLink>{entry.title}</EntryLink>}
      </>}
    </EntryItemWrap>
    {entry.submenus && entry.submenus.length > 0 && <RenderSubMenu id={id} submenus={entry.submenus} />}
  </Entry>
}

export default MenuEntry
