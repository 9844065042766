module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"whu-ee","short_name":"whu-ee","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d05dec1860d4d1c3f4e446cb921f8c44"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"redirect":false,"defaultLanguage":"en","i18nextOptions":{"debug":false,"interpolation":{"escapeValue":false},"defaultNS":"common","fallbackLng":"en"},"pages":[{"matchPath":"/:lang?/news/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"WHU – Otto Beisheim School of Management","titleTemplate":"%s | WHU Executive Education","openGraph":{"type":"website","title":"Executive Education"},"twitter":{"cardType":"summary","site":"@whu_edu","handle":"@whu_edu"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"anonymize":true,"trackingId":"UA-127299545-1"},"facebookPixel":{"pixelId":"1085977858149892"}},
    }]
