import React, { useMemo } from 'react'
import PropTypes from 'prop-types';

import * as S from './styles';
import { AvailableColors } from '../ThemeProvider';
import Icon from '../Icon';

const VARIANT_MAP = {
  contained: S.ButtonContained,
  outlined: S.ButtonOutlined,
  clean: S.ButtonClean,
  text: S.ButtonText,
};

type ButtonProps<T> = {
  as?: string | React.ComponentType<any>
  variant?: keyof typeof VARIANT_MAP
  color?: typeof AvailableColors[number]
  iconName?: string
  iconOnLeft?: boolean
  fill?: boolean
  small?: boolean
  iconProps?: React.ComponentProps<typeof Icon>
  label?: string
} & T

function useGetComponent<T>(variant: keyof typeof VARIANT_MAP) {
  return useMemo(() => {
    return VARIANT_MAP[variant] as React.FC<ButtonProps<T>>
  }, [variant]);
}

function Button<T>({
  fill,
  small,
  variant = 'contained',
  iconProps,
  iconName,
  iconOnLeft,
  children,
  label,
  as,
  color,
  ...rest
}: React.PropsWithChildren<ButtonProps<T>>) {
  const Component = useGetComponent(variant);

  const icon = iconName && (
    <Icon
      name={iconName}
      size={14}
      color='inherit'
      {...iconProps}
    />
  )

  return (
    <Component
      as={as}
      color={color}
      $fill={fill ? 1 : 0}
      $small={small ? 1 : 0}
      $iconOnLeft={iconOnLeft ? 1 : 0}
      {...rest}
    >
      { iconOnLeft && icon }
      <span>{children || label}</span>
      { !iconOnLeft && icon }
    </Component>
  )
};

Button.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'contained', 'clean', 'text']),
  color: PropTypes.oneOf(AvailableColors),
  iconName: PropTypes.string,
  iconOnLeft: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'contained',
  fill: false,
  color: 'primary',
  iconOnLeft: false,
};

export { S as ButtonStyles };
export default Button;
