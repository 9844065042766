import React, { useCallback, useState } from 'react'
import { AvailableColors, light } from '../ThemeProvider/palette'

import SubMenu from "./SubMenu"

import { Burger, Nav, Entries, Entry, SubMenus, SubMenusDropdown, BodyNoScroll } from "./Menu.styles"
import MenuEntry from './MenuEntry'
import { MenuContextProvider, useMenuContext } from './MenuContext'

export type MenuSubEntry = {
  id: string
  title: string
  href?: string
  items?: MenuSubEntry[]
}

export type SubMenu = {
  id: string
  title: string
  subtitle?: string
  cta?: {
    label: string
    href: string
  }

  items?: MenuSubEntry[]

  icon?: string
  palette?: {
    variant: typeof AvailableColors[number]
    accent: typeof AvailableColors[number]
  }
}

export type MenuEntryType = {
  id: string
  submenus?: SubMenu[]
  title?: string
  href?: string
  item?: React.ReactElement
} & ({
  title: string
  href?: string
} | {
  item: React.ReactElement
})
type MenuProps = {
  entries: MenuEntryType[]
}
const Menu: React.FC<MenuProps> = ({ entries }) => {
  const { menuOpen, setMenuOpen } = useMenuContext()

  const toggleMenu = useCallback(() => {
    setMenuOpen(open => !open)
  }, [setMenuOpen])

	return <>
    <BodyNoScroll $open={menuOpen} />
    <Burger open={menuOpen} onClick={toggleMenu} />
    <Nav $open={menuOpen}>
      <Entries>
        {entries.map((entry) => {
          return <MenuEntry entry={entry} key={entry.id} />
        })}
      </Entries>
    </Nav>
	</>
}

export default Menu
