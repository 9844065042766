import React, { useMemo } from 'react'
import PropTypes from 'prop-types';

// @ts-ignore
import primaryLogo from '!!@svgr/webpack?+svgo,+titleProp,+ref!file-loader!./logo.svg';
// @ts-ignore
import cleanLogo from '!!@svgr/webpack?+svgo,+titleProp,+ref!file-loader!./logo-clean.svg';

type SizeTuple = [number, number]
const primarySize: SizeTuple = [209.23, 39.22]
const cleanSize: SizeTuple = [1147.093, 377.947]

const calculateSize = (width?: number, height?: number, clean?: boolean): SizeTuple => {
  if (width && height) {
    return [width, height]
  }

  const [baseWidth, baseHeight] = clean ? cleanSize : primarySize
  if (width) {
    return [width, (baseHeight / baseWidth) * width]
  }

  if (height) {
    return [(baseWidth / baseHeight) * height, height]
  }

  return [baseWidth, baseHeight]
}

const Identity: React.FC<{
  clean?: boolean
  className?: string
  width?: number
  height?: number
}> = ({
  clean,
  className,
  width: _width,
  height: _height,
}) => {
  const [width, height] = useMemo<SizeTuple>(() => {
    return calculateSize(_width, _height, clean).map((item) => Math.ceil(item)) as SizeTuple
  }, [_width, _height, clean])

  return <img
    src={clean ? cleanLogo : primaryLogo}
    alt="WHU Logo"
    className={className}
    width={width}
    height={height}
  />
};

Identity.propTypes = {
  clean: PropTypes.bool,
  className: PropTypes.string,
};

Identity.defaultProps = {
  clean: false,
};

export default Identity;
