import React from 'react';
import styled from 'styled-components';

const S = {};
S.Menu = styled.nav`
  color: ${(props) => props.theme.palette.white};
  margin-bottom: 15px;
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li:first-child > * {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 5px;
    
    &:after {
      height: 3px;
      width: 12px;
    }
  }
  
  li {
    a {
      text-decoration: none;
      color: inherit;
      
      &:hover:after {
        width: 100%;
      }
    }

    > * {
      border-bottom: 1px dashed transparent;
      display: inline-block;    

      &:after {
        content: '';
        display: block;
        height: 1px;
        width: 0;
        background: currentColor;
        transition: all 0.5s ease-in-out;
      }
    }    
  }
`;

const FooterMenu = ({
  children,
}) => {
  return (
    <S.Menu>
      <ul>
        {React.Children.map(children, (child, i) => (<li key={i}>{child}</li>))}
      </ul>
    </S.Menu>
  )
};

export default FooterMenu;
