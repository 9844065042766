import React from 'react';
import AppBar from 'ui/elements/AppBar'
import { MenuContextProvider, useMenuContext } from 'ui/elements/Menu/MenuContext'
import { SrOnly } from 'ui/elements/Text/styles'

import Navigation from '../Navigation';

import * as S from "./Header.styles"
import { useTranslation } from 'gatsby-plugin-react-i18next'

const IdentityComponent = () => {
  const [t] = useTranslation("common")
  const { subMenuOpen, resetSubMenu, menuTitle } = useMenuContext()

  return (<>
    <S.IdentityWrap $open={subMenuOpen}>
      <S.Link to='/'>
        <S.Identity width={290} height={74} />
        <SrOnly>{t("goToIndex")}</SrOnly>
      </S.Link>
      <S.Back onClick={resetSubMenu} />
      <S.MenuTitle>{menuTitle}</S.MenuTitle>
    </S.IdentityWrap>
  </>)
};

const Header = ({
  hasNavigation,
}) => {
  return (
    <MenuContextProvider>
      <AppBar
        left={<IdentityComponent />}
        right={<Navigation hasNavigation={hasNavigation} />}
      />
    </MenuContextProvider>
  );
};

export default Header;
