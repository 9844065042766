import React, { useMemo } from 'react'
import { Link as GatsbyLink, useI18next } from 'gatsby-plugin-react-i18next';

export const useNavigation = () => {
  const { navigate } = useI18next();

  return navigate;
};

const Link = ({ to, children, ...props }) => {
  const toWithSlash = useMemo(() => {
    if (to.slice(-1) === '/') {
      return to
    }

    return `${to}/`
  }, [to])

  return <GatsbyLink to={toWithSlash} {...props}>{children}</GatsbyLink>
}
export default Link;
