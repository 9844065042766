import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-grid-system';
import Button from 'ui/elements/Button'
import Cookies from 'js-cookie';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'widgets/Link';

import * as S from './styles';
import { useCookiesMap, GDPRConsentCookie, useCookiesTrack } from './utils';
import CookiesPreferences from './CookiesPreferences';
import { useIsSSR } from '../../../utils/isSSR'

const GDPRConsent = () => {
  const { t } = useTranslation('gdpr');
  const initTrack = useCookiesTrack();
  const GDPRCookies = useCookiesMap();
  const [consent, setConsent] = useState(false);

  const setAllCookies = (value) => {
    GDPRCookies.forEach((cookie) => {
      Cookies.set(cookie.cookieName, value, { expires: 365 });
    })
  }

  useEffect(() => {
    setConsent(Cookies.get(GDPRConsentCookie) === 'true');
  }, []);

  const setGDPRCookie = () => {
    Cookies.set(GDPRConsentCookie, true, { expires: 365 });
    setConsent(true);
  }

  const handleAcceptAll = (ev) => {
    ev.preventDefault();
    setAllCookies(true);
    setGDPRCookie();

    initTrack();
  }

  const handleRejectAll = (ev) => {
    ev.preventDefault();

    setAllCookies(false);
    setGDPRCookie();
  }

  if (consent) {
    return null;
  }

  if (process.env.GATSBY_NO_COOKIE_BANNER) {
    return null
  }

  return (
    <S.GDPRConsent>
      <Container>
        <Row align='center' justify='between'>
          <Col xs={12} md={7}>
            <strong>{t('banner.title')}</strong>
            <p>{t('banner.content')}</p>
            <Button
              as={Link}
              variant='clean'
              to='/privacy-statement'
            >
              {t('banner.privacyStatement')}
            </Button>
          </Col>

          <Col xs={12} md={4}>
            <S.Actions>
              <Button
                variant='outlined'
                color='success'
                onClick={handleAcceptAll}
              >
                {t('banner.acceptAll')}
              </Button>

              <Button
                variant='clean'
                color='otherBeta'
                onClick={handleRejectAll}
              >
                {t('banner.declineAll')}
              </Button>

              <CookiesPreferences cookies={GDPRCookies}>
                {(openModal) => (
                  <Button
                    variant='clean'
                    onClick={openModal}
                  >
                    {t('banner.preferences')}
                  </Button>
                )}
              </CookiesPreferences>
            </S.Actions>
          </Col>
        </Row>
      </Container>
    </S.GDPRConsent>
  );
};

const RenderGDPRConsent = () => {
  // prevent render of modal in ssr as that causes problems with cookie checks
  const isSSR = useIsSSR()

  if (isSSR) {
    return null;
  }

  return <GDPRConsent />
}

export default RenderGDPRConsent;
