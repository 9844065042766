import React from 'react';
import ProTypes from 'prop-types';

import * as S from './styles';
import { AvailableColors } from '../ThemeProvider';

const TextInput = ({
  color,
  value,
  fill,
  after,
  ...rest
}) => {
  return (
    <S.TextInput color={color} fill={fill ? 1 : 0}>
      <S.Input value={value} {...rest} />
      {
        after && (<S.After>{after}</S.After>)
      }
    </S.TextInput>
  );
};

TextInput.propTypes = {
  value: ProTypes.string,
  color: ProTypes.oneOf(AvailableColors),
  fill: ProTypes.bool,
  after: ProTypes.node,
};

TextInput.defaultProps = {
  color: 'primary',
  fill: false,
};

export default TextInput;

