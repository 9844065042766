import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const AppBar = ({
  left,
  right,
}) => {
  return (
    <S.AppBar>
      <S.RowContainer>
        <S.Row>
          <S.LeftContainer>
            {left}
          </S.LeftContainer>
          <S.RightContainer>
            {right}
          </S.RightContainer>
        </S.Row>
      </S.RowContainer>
    </S.AppBar>
  )
};

AppBar.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
};

export { S as AppBarStyles };
export default AppBar;
