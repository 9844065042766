import React, { useCallback, useMemo } from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

import { Wrapper, IconWrapper, ContentWrapper, ItemsWrap, Items, Title, SubTitle, ButtonWrap } from "./SubMenu.styles"
import type { SubMenu as SubMenuData } from './Menu'
import SubMenuItem from './SubMenuItem'
import Button from '../Button'
import Icon from '../Icon'
import { useMenuContext } from './MenuContext'

type SubMenuProps = {
  parent_id: string
  submenu: SubMenuData
}
const SubMenu: React.FC<SubMenuProps> = ({ parent_id, submenu }) => {
  const { isSubMenuOpen, setSubMenuOpen } = useMenuContext()

  const subMenuId = useMemo(() => [parent_id, submenu.id], [parent_id, submenu])
  const hasItems = submenu.items && submenu.items.length > 0
  const subMenuOpen = useMemo(() => {
    return isSubMenuOpen(subMenuId)
  }, [subMenuId, isSubMenuOpen])

  const toggleSubMenu = useCallback(() => {
    setSubMenuOpen(subMenuId)
  }, [subMenuId])

	return <Wrapper $palette={submenu.palette}>
    <IconWrapper>
      {submenu.icon && <Icon name={submenu.icon} size={100} color={submenu.palette?.variant} />}
    </IconWrapper>
    <ContentWrapper>
      {(hasItems || !submenu.cta) && <Title $open={subMenuOpen} $hasChildren={hasItems} onClick={toggleSubMenu}>{submenu.title}</Title>}
      {!hasItems && submenu.cta && <Title as={Link} to={submenu.cta.href}>{submenu.title}</Title>}
      <SubTitle>{submenu.subtitle}</SubTitle>

      {hasItems && <ItemsWrap>
          <Items $open={subMenuOpen}>
          {submenu.items?.map((item) => <SubMenuItem parent_id={subMenuId} palette={submenu.palette} item={item} key={`item-${item.id}`} />)}
        </Items>
      </ItemsWrap>}

      {submenu.cta && <ButtonWrap>
        <Button as={Link} to={submenu.cta.href} color={submenu.palette?.variant}>
          {submenu.cta.label}
        </Button>
      </ButtonWrap>}
    </ContentWrapper>
	</Wrapper>
}

export default SubMenu
