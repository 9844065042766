import React, { useEffect } from 'react'
import PropTypes from 'prop-types';

import { AvailableColors } from '../ThemeProvider';
import { Icon as SIcon, Box } from './styles';
import { useIconsContext } from 'contexts/IconsContext'

const DefaultSize = 16;

const Icon: React.FC<{
  name: string
  size: number
  color?: typeof AvailableColors[number]
  className?: string
  boxed?: boolean
} & ({
  boxed: true
  background?: string
} | {
  boxed?: false
  background?: never
})> = ({
  name,
  size,
  color,

  background,
  boxed,
  className,
}) => {
  const { addIcon, removeIcon } = useIconsContext()
  useEffect(() => {
    if (!boxed && background) {
      console.warn('background property is available only for boxed variant');
    }
  }, [boxed, background])

  useEffect(() => {
    addIcon(name)

    return () => {
      removeIcon(name)
    }
  }, [name])

  const Icon = (
    <SIcon
      viewBox={`0 0 ${size} ${size}`}
      $color={background ? 'inherit' : color ?? 'inherit'}
      width={size}
      height={size}
      className={className}
    >
      <use xlinkHref={`#${name}`} />
    </SIcon>
  );

  if (boxed) {
    return (
      <Box $background={background}>
        {Icon}
      </Box>
    )
  }

  return Icon;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.oneOf(AvailableColors),
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: DefaultSize,
  color: 'inherit',
  boxed: false,
};

export default Icon;
