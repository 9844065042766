import rawSlugify from 'slugify'
import _flow from 'lodash/flow';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { useMemo } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const flattenResponse = (data, root) => {
  const path = root ? `${root}.edges` : 'edges';

  return _flow(
    (d) => _get(d, path, []),
    (d) => _map(d, 'node'),
  )(data);
};

export const slugify = (string, opts = { replacement: '-', lower: true }) => {
  if (!string) return '#';

  return rawSlugify(string, opts);
};

const slugifySanity = (string, locale) => {
  if (!string) return null;

  return rawSlugify(string, {
    remove: /[^a-zA-Z0-9äöüÄÖÜß\-_\s]/g,
    replacement: '-',
    lower: true,
    locale,
  }).replace(/-+/g, '-');
};

export const buildPersonaData = (member) => {
  let fullName = `${member.firstName} ${member.lastName}`;
  let position = member.position;

  if (member.title) {
    fullName = `${member.title} ${fullName}`;
  }

  if (member.organization) {
    position = `${position} - ${member.organization}`
  }

  return { fullName, position }
};

function generateInternalLink(reference, locale) {
  const { _type, title } = reference

  let slug;

  if (typeof title === "string") {
    slug = slugifySanity(title, locale)
  } else {
    slug = slugifySanity(title[locale], locale)
  }

  if (_type === 'program') {
    return `/open-programs/${slug}`;
  }

  if (_type === 'conference') {
    return `/conferences/${slug}`;
  }

  if (_type === 'news') {
    return `/news/${slug}`;
  }

  if (slug && slug.current) {
    return `/${slug.current}`
  }

  return '/404';
}

// Since 'slug' WAS missing we had to dynamically create links
// https://github.com/saschabock/whu-execed-frontend/issues/25#issuecomment-655028671
// we started generating valid slugs on build-time while upgrading to gatsby v3
// this method exists for backwards compatibility
const getInternalLink = (reference, locale) => {
  if (!reference) return '/404';

  const { _type, slug } = reference;

  // fallback in case we get our data from raw field
  if (!slug) {
    return generateInternalLink(reference, locale)
  }

  if (_type === 'program') {
    return `/open-programs/${slug}`;
  }

  if (_type === 'conference') {
    return `/conferences/${slug}`;
  }

  if (_type === 'news') {
    return `/news/${slug}`;
  }

  if (slug && slug.current) {
    return `/${slug.current}`
  }

  return '/404';
};

export const useGetInternalLink = (reference) => {
  const { i18n: { language } } = useTranslation()

  return useMemo(() => {
    return getInternalLink(reference, language)
  }, [reference, language])
}

export const getDownloadLink = (reference) => {
  const asset = _get(reference, 'asset', {});
  const url = asset.url;
  const filename = asset.originalFilename;

  if (!url || !filename) return undefined;

  return `${url}?dl=${filename}`;
}
