import React from 'react'
import * as S from './styles';
import { AvailableColors } from '../ThemeProvider';

const OpticalHeading: React.FC<{
  level: 1 | 2 | 3 | 4 | 5 | 6
  renderAs?: string
  color?: typeof AvailableColors[number] | "inherit"
  className?: string
}> = ({
  level = 1,
  renderAs,
  children,
  color = 'inherit',
  className,
}) => {
  return (
    <S.Heading
      as={renderAs ? renderAs : 'span'}
      level={level}
      color={color}
      className={className}
    >
      {children}
    </S.Heading>
  )
};

export default OpticalHeading
