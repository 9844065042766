import styled from 'styled-components';
import { Icon } from '../Icon/styles';

export const Button = styled.a`
  display: ${(props) => props.$fill ? 'block' : 'inline-block'};
  width: ${(props) => props.$fill ? '100%' : 'auto'};
  padding: ${(props) => 
    props.$small ? `
      ${props.theme.utils.inputs.padding.vertical / 2}px
      ${props.theme.utils.inputs.padding.horizontal / 2}px
    ` :
    `
      ${props.theme.utils.inputs.padding.vertical}px
      ${props.theme.utils.inputs.padding.horizontal}px
    `
  };
  font-size: ${(props) => 
    props.$small ? '1rem' : `${props.theme.utils.inputs.fontSize}rem`
  };
  
  justify-content: center;
  border-style: solid;
  border-width: 1px; 
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  position: relative;
  
  a {
    color: inherit;
    text-decoration:none;
  }

  > ${Icon} {
    margin: ${(props) => props.$iconOnLeft ? '0 10px 0 0' : '0 0 0 10px'};
  }
`;


export const ButtonOutlined = styled(Button)`
  color: ${(props) => props.theme.palette[props.color]};
  border-color: ${(props) => props.theme.palette[props.color]};
  
  &:hover {
    background: ${(props) => props.theme.palette[props.color]}; 
    color: ${(props) => props.theme.palette.white};
  }
`;


export const ButtonContained = styled(Button)`
  color: ${(props) => props.theme.palette.white};
  background-color: ${(props) => props.theme.palette[props.color]};
  border-color: ${(props) => props.theme.palette[props.color]};
  
  &:hover {
    background: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette[props.color]}; 
    color: ${(props) => props.theme.palette[props.color]}; 
  }
`;

export const ButtonClean = styled(Button)`
  color: ${(props) => props.theme.palette[props.color]};
  border: 0;
  padding-left: 0;
  padding-right: 0;
`;

export const ButtonText = styled(Button)`
  color: ${(props) => props.theme.palette[props.color]};
  text-decoration: none;
  font-weight: bold;
  border: 0;
  padding: 0;
  font-size: inherit;
`;
