import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'widgets/Link';

import Newsletter from './Newsletter';
import Copyright from './Copyright';
import FooterMenu from './FooterMenu';
import AccreditationLogos from './AccreditationLogos';
import SocialMedia from './SocialMedia';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'

const S = {};
S.Footer = styled.footer`
  background: ${(props) => props.theme.palette.gunmetal};
  color: ${(props) => props.theme.palette.white};
  padding: 70px 0 30px;
  font-size: 1.4rem;
  line-height: 1.8;
`;

S.Separator = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background: rgb(151, 151, 151);
  margin: 50px 0;
`;

S.Link = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <S.Footer>
      <Container fluid xs sm>
        <Row>
          <Col xs={12} md={6}>
            <Newsletter />
          </Col>
        </Row>
        <S.Separator />
        <Row align='start'>
          <Col xs={5} md={2}>
            <OpticalHeading level={2} renderAs="p">
              Excellence in Management Education
            </OpticalHeading>
            <p>
              WHU – Otto Beisheim School of Management
            </p>
            <p>
              Erkrather Str. 224a<br />
              40244 Düsseldorf
            </p>
            <p>
              <S.Link href="mailto:execed@whu.edu">execed@whu.edu</S.Link>
            </p>
          </Col>
          <Col xs={12} md={3} push={{ md: 1 }}>
            <FooterMenu>
              <span>{t('menuItems.information')}</span>
              <Link to='/information/admissions'>Admission</Link>
              <Link to='/information/financing-options'>{t('menuItems.financingOptions')}</Link>
              <Link to='/information/faq'>{t('menuItems.faq')}</Link>
              <Link to='/information/diversity-scholarships'>Diversity Scholarships</Link>
            </FooterMenu>
            <FooterMenu>
              <Link to='/information/hrd-offerings'>{t('menuItems.hrdOfferings')}</Link>
            </FooterMenu>
          </Col>
          <Col xs={12} md={3} push={{ md: 1 }}>
            <FooterMenu>
              <Link to='/open-programs'>Open programs</Link>
              <Link to='/ects-granting-programs'>ECTS-Granting Programs</Link>
            </FooterMenu>
            <FooterMenu>
              <Link to='/customized-programs-for-companies'>Customized Programs</Link>
            </FooterMenu>
            <FooterMenu>
              <Link to='/conferences'>{t('menuItems.conferences')}</Link>
            </FooterMenu>
          </Col>
          <Col xs={12} md={3} push={{ md: 1 }}>
            <FooterMenu>
              <Link to='/the-experience/8-reasons-to-join-us'>The Experience</Link>
              <Link to='/the-experience/faculty'>{t('menuItems.faculty')}</Link>
              <Link to='/about-whu'>{t('menuItems.about')}</Link>
            </FooterMenu>
            <FooterMenu>
              <Link to='/news'>News</Link>
            </FooterMenu>
            <FooterMenu>
              <Link to='/imprint'>{t('menuItems.imprint')}</Link>
            </FooterMenu>
            <FooterMenu>
              <Link to='/privacy-statement'>{t('menuItems.privacyStatement')}</Link>
            </FooterMenu>
          </Col>
        </Row>
        <S.Separator />
        <Row align='center' justify='center'>
          <Col xs={12} lg={3}>
            <SocialMedia />
          </Col>
          <Col xs={12} lg={6}>
            <Copyright />
          </Col>
          <Col xs={12} lg={3}>
            <AccreditationLogos />
          </Col>
        </Row>
      </Container>
    </S.Footer>
  )
};

export default Footer;
