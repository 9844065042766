import styled from 'styled-components';

export const GDPRConsent = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid ${(props) => props.theme.palette.secondaryBeta};
  box-shadow: 0 0 4px ${(props) => props.theme.palette.secondaryBeta};
  background: ${(props) => props.theme.palette.white};
  padding: 2rem 0;
  z-index: 98;
  line-height: 1.5;
  font-size: 1.4rem;
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Cookie = styled.div`
  box-shadow: 0 0 2px currentColor;
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  font-weight: 700;
  background: ${(props) => props.$active ? props.theme.palette.paperAlpha : 'transparent'};
  
  label {
    padding: 1.5rem 0;
    margin-left: 1rem;
    flex: 1 1 auto;
    cursor: pointer;
  }
`;

export const CookiesPreferencesWrapper = styled.form`
  font-size: 1.4rem;

  ${Cookie} + ${Cookie} {
    margin-top: 2.5rem;
  }
`;

export const CookiesPreferencesHeader = styled.header`
  text-align: center;
  margin: 3rem 0;
  
  > * {
    margin: 0;
  }
`;

export const CookiesPreferencesFooter = styled.div`
  margin-top: 3rem;
`
