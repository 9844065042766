import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

const files = require.context('ui/elements/Icon/icons', true, /\.svg$/)
const iconMap = files.keys().reduce((acc, file) => {
  // extract the file's name, excluding path and extension
  const filename = file.match(/^\.\/(.*)\.svg/)?.[1]

  if (!filename) {
    return acc
  }

  return {
    ...acc,
    [filename]: file,
  }
}, {}) as Record<string, string>

type IconMapKey = keyof typeof iconMap

const useCreateContext = () => {
  const [icons, setIcons] = useState<Record<IconMapKey, number>>({})

  const addIcon = useCallback((icon: IconMapKey) => {
    setIcons((icons) => {
      return {
        ...icons,
        [icon]: (icons[icon] ?? 0) + 1,
      }
    })
  }, [])

  const removeIcon = useCallback((icon: IconMapKey) => {
    setIcons((icons) => {
      return {
        ...icons,
        [icon]: (icons[icon] ?? 0) - 1,
      }
    })
  }, [])

  useEffect(() => {
    const activeIcons = Object.entries(icons)
      .filter(([, value]) => value > 0)
      .map(([key]) => key)

    Object.entries(iconMap)
      .filter(([key]) => activeIcons.includes(key))
      .forEach(([, path]) => {
        files(path)
      })
  }, [icons])

  return {
    addIcon,
    removeIcon,
  }
}
const IconsContext = createContext<ReturnType<typeof useCreateContext> | null>(null)

export const useIconsContext = () => {
  const ctx = useContext(IconsContext)

  if (ctx === null) {
    throw new Error('No IconsContextProvider available.')
  }

  return ctx
}

export const IconsContextProvider: React.FC = ({ children }) => {
  const ctx = useCreateContext()

  return <IconsContext.Provider value={ctx}>
    {children}
  </IconsContext.Provider>
}
