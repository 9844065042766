import { useEffect, useMemo, useState } from 'react'

export function useIsSSR() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return useMemo(() => {
    return !hasMounted || typeof window === 'undefined'
  }, [hasMounted])
}
