import React from 'react';

import styled from 'styled-components';
import { breakpoint } from 'ui/elements/ThemeProvider'
import Icon from 'ui/elements/Icon'
import { SrOnly } from 'ui/elements/Text/styles'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const S = {};
S.SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${breakpoint.lg`
    justify-content: flex-start;
  `};
 
  a {
    color: ${(props) => props.theme.palette.white};
    margin-right: 20px;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

const SocialMedia = () => {
  const [t] = useTranslation("common")
  return (
    <S.SocialMedia>
      <a
        href='https://www.facebook.com/whu.ExecutiveEducation'
        target='_blank'
        rel='noreferrer'
      >
        <Icon name='facebook' size={32} />
        <SrOnly>{t("facebook")}</SrOnly>
      </a>
      <a
        href='https://www.linkedin.com/company/whu-executive-education'
        target='_blank'
        rel='noreferrer'
      >
        <Icon name='linkedin' size={32} />
        <SrOnly>{t("linkedIn")}</SrOnly>
      </a>
    </S.SocialMedia>
  )
};

export default SocialMedia;
