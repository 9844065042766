import styled, { css } from 'styled-components';
import { breakpoint } from 'ui/elements/ThemeProvider'

export const Label = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  
  color: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
`;

export const Language = styled.span`
  text-transform: uppercase;
  padding-right: 4px;
  pointer-events: ${(props) => props.$active ? 'none' : 'all'}; 
  
  ${Label} {
    color: ${(props) => props.$active ? props.theme.palette.primary : props.theme.palette.black};
  }
  
  &:hover ${Label} {
    text-decoration: underline;
  }

  &:after {
    content: '|';
    padding-left: 4px;
  }

  &:last-child:after {
    content: '';
  }
`;

export const LanguageSwitcher = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint.max_lg(() => css`
    width: 100%;
    
    padding: 10px 18px;
    border-bottom: 1px solid ${props => props.theme.palette.paperGamma};
  `)}
`;
