import styled, { css } from 'styled-components';
import { breakpoint } from '../ThemeProvider';

const Level1 = css`
  font-size: 2.3em;
  
  ${breakpoint.md`
    font-size: 2.9em;
  `}
`;

const Level2 = css`
  font-size: 1.8em;
  font-weight: 700;
`;

const Level3 = css`
  font-size: 1.8em;
  font-weight: 300;
`;

const Level4 = css`
  font-size: 1.3em;
  font-weight: 700;
`;

const Level5 = css`
  font-size: 1.3em;
  font-weight: 500;
`;

const Level6 = css`
  font-size: 1em;
  font-weight: 700;
`;

const levels = {
  1: Level1,
  2: Level2,
  3: Level3,
  4: Level4,
  5: Level5,
  6: Level6,
};

export const Heading = styled.span`
  display: block;
  font-weight: bold;
  font-family: 'Helvetica Neue', 'Arial Regular', sans-serif;
  margin: 0 0 15px;
  padding: 0;
  line-height: 1.2;
  color: ${(props) => props.color === 'inherit' ? 'inherit' : props.theme.palette[props.color]};
  a {
    color: ${(props) => props.color === 'inherit' ? 'inherit' : props.theme.palette[props.color]};
    text-decoration: none;
    font-weight: inherit;
  }
  
  ${(props) => levels[props.level]}};
`;

