import React, { useCallback, useContext } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next';

import * as S from './styles';
import { LanguageSwitcherContext } from '../../../contexts/LanguageSwitcherContext'
import { navigate } from 'gatsby'

const LanguageSwitcher = () => {
  const { languages, language, changeLanguage } = useI18next();

  const { forceTranslatedUrls } = useContext(LanguageSwitcherContext)

  const onChangeLanguage = useCallback((lang) => () => {
    const override = forceTranslatedUrls?.[lang]

    if (!override) {
      return changeLanguage(lang)
    }

    return navigate(override)
  }, [forceTranslatedUrls, changeLanguage])

  return (
    <S.LanguageSwitcher>
      {
        languages.map((lang) => {
          return (
            <S.Language
              key={lang}
              $active={language === lang}
              onClick={onChangeLanguage(lang)}
            >
              <S.Label>
                {lang}
              </S.Label>
            </S.Language>
          )
        })
      }
    </S.LanguageSwitcher>
  )
};

export default LanguageSwitcher;
