import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { globalHistory } from '@reach/router'

const useCreateContext = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [_subMenuOpen, _setSubMenuOpen] = useState<string[] | undefined>()
  const [menuTitle, setMenuTitle] = useState<string | undefined>()

  const onNavigation = useCallback(() => {
    setMenuOpen(false)
    _setSubMenuOpen(undefined)
  }, [])

  const setSubMenuOpen = useCallback((menu_id: string[]) => {
    _setSubMenuOpen(old => {
      // calling with the current id or a parent causes close
      if (old?.join(".").startsWith(menu_id.join("."))) {
        return menu_id?.slice(0, menu_id?.length - 1)
      }

      return menu_id
    })
  }, [])

  const setPreviousSubMenuOpen = useCallback(() => {
    _setSubMenuOpen(val => val?.slice(0, val?.length - 1))
  }, [])

  const resetSubMenu = useCallback(() => {
    _setSubMenuOpen(undefined)
  }, [])

  const isSubMenuOpen = useCallback((id: string[]) => {
    if (!_subMenuOpen || _subMenuOpen.length === 0) {
      return false
    }

    return _subMenuOpen.join(".").startsWith(id.join("."))
  }, [_subMenuOpen])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      // automatically reset menu on navigation
      if (action === "PUSH") {
        onNavigation()
      }
    })
  }, [])

  useEffect(() => {
    if (_subMenuOpen !== undefined) {
      return
    }

    setMenuTitle(undefined)
  }, [_subMenuOpen])

  useEffect(() => {
    if (menuOpen) {
      return
    }

    _setSubMenuOpen(undefined)
  }, [menuOpen])

  const subMenuOpen = useMemo(() => {
    return menuOpen && !!_subMenuOpen && _subMenuOpen.length > 0
  }, [_subMenuOpen, menuOpen])

  return {
    menuOpen,
    setMenuOpen,

    subMenuOpen,
    setSubMenuOpen,
    setPreviousSubMenuOpen,
    resetSubMenu,
    isSubMenuOpen,

    menuTitle,
    setMenuTitle,

    onNavigation,
  }
}
const MenuContext = createContext<ReturnType<typeof useCreateContext> | null>(null)

export const useMenuContext = () => {
    const ctx = useContext(MenuContext)

    if (ctx === null) {
        throw new Error("No MenuContextProvider available.")
    }

    return ctx
}

export const MenuContextProvider: React.FC = ({ children }) => {
    const ctx = useCreateContext()

    return <MenuContext.Provider value={ctx}>
        {children}
    </MenuContext.Provider>
}
