// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-conferences-index-jsx": () => import("./../../../src/templates/conferences/index.jsx" /* webpackChunkName: "component---src-templates-conferences-index-jsx" */),
  "component---src-templates-conferences-single-jsx": () => import("./../../../src/templates/conferences/single.jsx" /* webpackChunkName: "component---src-templates-conferences-single-jsx" */),
  "component---src-templates-faculty-index-jsx": () => import("./../../../src/templates/faculty/index.jsx" /* webpackChunkName: "component---src-templates-faculty-index-jsx" */),
  "component---src-templates-faculty-single-jsx": () => import("./../../../src/templates/faculty/single.jsx" /* webpackChunkName: "component---src-templates-faculty-single-jsx" */),
  "component---src-templates-homepage-index-jsx": () => import("./../../../src/templates/homepage/index.jsx" /* webpackChunkName: "component---src-templates-homepage-index-jsx" */),
  "component---src-templates-news-index-jsx": () => import("./../../../src/templates/news/index.jsx" /* webpackChunkName: "component---src-templates-news-index-jsx" */),
  "component---src-templates-news-single-jsx": () => import("./../../../src/templates/news/single.jsx" /* webpackChunkName: "component---src-templates-news-single-jsx" */),
  "component---src-templates-page-landing-page-tsx": () => import("./../../../src/templates/page/LandingPage.tsx" /* webpackChunkName: "component---src-templates-page-landing-page-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-programs-index-jsx": () => import("./../../../src/templates/programs/index.jsx" /* webpackChunkName: "component---src-templates-programs-index-jsx" */),
  "component---src-templates-programs-single-jsx": () => import("./../../../src/templates/programs/single.jsx" /* webpackChunkName: "component---src-templates-programs-single-jsx" */)
}

