import styled from 'styled-components';

export const Icon = styled.svg<{
  $color: string
}>`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.$color === 'inherit' ? 'currentColor' : props.theme.palette[props.$color]};
  stroke: ${(props) => props.$color === 'inherit' ? 'currentColor' : props.theme.palette[props.$color]};
`;

export const Box = styled.div<{
  $background?: string
}>`
  background: ${(props) => props.$background ? props.theme.palette[props.$background] : 'transparent'};
  color: ${(props) => props.theme.palette.white};
  text-align: center;
  position: relative;
  width: 100%;
  
  ${Icon} {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`
